import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "components/layout";
import SEO from "components/seo";
import Contact from "components/contact";

export default function AboutPage({
    data
  }) {

    useEffect(() => {
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const Plyr = require('plyr')
        Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p))
      }
    });

    return (
        <Layout>
            <SEO
                pageData={{
                    title: data.markdownRemark.frontmatter.title,
                    description: data.markdownRemark.frontmatter.metadescription
                }}
            />

            <div className="bg-green-50">
                <div className="max-w-screen-md mx-auto px-4 text-3xl md:text-5xl font-black text-center pt-16">How can Hunter Family Spending Planners help you?</div>
            </div>

            {data.markdownRemark.frontmatter.content.map((edge,i) => {
                let orderClass = (i%2 === 1 ? 'md:order-2' : '' )
                let paddingClass = (i%2 === 1 ? 'lg:pl-24' : 'lg:pr-24' )
                let firstClass = (i === 0 ? 'bg-green-50 border-b border-green-100' : '' )
                return (
                    <div className={firstClass} key={i}>
                        <div className="page mx-auto px-4 max-w-screen-xl grid md:grid-cols-2 md:gap-5 py-12 md:py-32">
                            <div className={`mx-auto ${paddingClass} ${orderClass}`}>
                                <div className="text-2xl md:text-3xl font-extrabold">{edge.title1}</div>
                                <div className="text-2xl md:text-3xl font-extrabold mb-4 text-green-700">{edge.title2}</div>
                                <div className="text-lg">
                                    <div dangerouslySetInnerHTML={{ __html: edge.text}} />
                                </div>
                            </div>
                            {edge.image &&
                                <div className="md:px-4">
                                {
                                    (
                                        !edge.image.childImageSharp
                                        && edge.image.extension === 'svg'
                                        &&
                                        <img src={edge.image.publicURL} alt="" className="mx-auto" />
                                    )
                                    ||
                                    <GatsbyImage image={edge.image.childImageSharp.gatsbyImageData} alt="" />
                                }
                                </div>
                            }
                            {edge.video &&
                                <div className="js-player" data-plyr-provider="youtube" data-plyr-embed-id={edge.video}></div>
                            }
                        </div>
                    </div>
                );
            })}

            <div className="bg-green-100 py-12 md:py-24">
                <div className="page mx-auto px-4 max-w-screen-xl mb-12 text-3xl font-extrabold text-center md:text-left ">
                    <span className="inline-block pb-1 border-b-4 border-green-700">Who we are</span>
                </div>
                <div className="page mx-auto px-4 max-w-screen-xl grid md:grid-cols-2 gap-20">
                {data.markdownRemark.frontmatter.profiles.map((edge,i) => {
                    return (
                        <div key={i}>
                            {edge.image &&
                                <div className="mb-6 text-center lg:float-left lg:mr-8">
                                {
                                    (
                                        !edge.image.childImageSharp
                                        && edge.image.extension === 'svg'
                                        &&
                                        <img src={edge.image.publicURL} alt="" className="rounded-full shadow" />
                                    )
                                    ||
                                    <GatsbyImage
                                        image={edge.image.childImageSharp.gatsbyImageData}
                                        alt=""
                                        className="rounded-full shadow" />
                                }
                                </div>
                            }
                            <div className="text-2xl md:text-2xl font-extrabold">{edge.name}</div>
                            <div className="mb-6">
                                <div dangerouslySetInnerHTML={{ __html: edge.text}} />
                            </div>
                        </div>
                    );
                })}
                </div>
            </div>
            <Contact />
        </Layout>
    );
}
export const pageQuery = graphql`query PageQuery {
  markdownRemark(frontmatter: {section: {eq: "about"}}) {
    frontmatter {
      title
      metadescription
      content {
        text
        title1
        title2
        video
        image {
          id
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
      profiles {
        name
        text
        image {
          id
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(width: 200, layout: FIXED)
          }
        }
      }
    }
  }
}
`